import React, { FC, useState } from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";
import { styles } from "./installer-page.styles";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";
import { Box } from "@mui/material";
import { t } from "@lingui/macro";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routing/routes";
import ButtonIcon from "./button.svg";
import { appInject } from "@core/di/utils";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { appReaction } from "@core/state-management/utils";
import classNames from "classnames";
import { StyledButton } from "@shared/components/buttons/styled-button";
import { getMacosInstallationSteps } from "@shared/resources/macos-installation-steps";
import { InfoIcon } from "@shared/images/icons/info-icon";
import { ArrowIcon } from "@shared/components/styled-select/arrow";
import { IInstallerViewModel } from "@shared/interfaces/installer-vm.interface";

export type InstallerPageProps = AppWithStyles<typeof styles>;

const InstallerPage: FC<InstallerPageProps> = ({
  classes,
}: InstallerPageProps) => {
  const navigate = useNavigate();

  const installerVM = appInject<IInstallerViewModel>(
    DI_TOKENS.installerViewModel,
  );

  const [_, render] = useState(0);

  const steps = getMacosInstallationSteps();

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1);
    }
  };
  const handlePrevious = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const localesViewModel = appInject<ILocalesViewModel>(
    DI_TOKENS.localesViewModel,
  );

  const handleDownload = (url: string) => {
    if (!url) return;
    const link = document.createElement("a");
    link.href = url;
    link.download = url.split("/").pop() || "file";
    link.click();
  };

  appReaction(
    () => localesViewModel.locale,
    () => render(_ + 1),
  );

  return (
    <Box
      sx={{
        height: "auto",
        padding: "36px 24px 116px 24px",
        [baseTheme.breakpoints.up(BreakpointLayout.LAPTOP)]: {
          padding: "50px 135px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          height: "auto",
          gap: "30px",
          position: "relative",
          [baseTheme.breakpoints.up(BreakpointLayout.LAPTOP)]: {
            height: "fit-content",
            alignItems: "flex-start",
            flexDirection: "row",
            gap: "20px",
          },
        }}
      >
        <div
          className={classNames(classes.item, classes.red)}
          onClick={() => navigate(ROUTES.courses)}
        >
          <div className={classes.label}>{t`Installer Software`}</div>
          <div
            className={classes.description}
          >{t`for S5 security system (V1)`}</div>
          <img
            className={classes.image}
            src={`${window.location.origin}/assets/laptop.png`}
            alt={""}
          />
        </div>
        <Box
          sx={{
            width: "auto",
            height: "60vh",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            backgroundImage: `url(${ButtonIcon})`,
            backgroundRepeat: "repeat-y",
            backgroundPositionY: "-7px",
            [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
              height: "fit-content",
            },
          }}
        >
          {installerVM.macosLink ? (
            <StyledButton
              sx={{
                width: "370px",
                height: "62px",
                fontSize: "18px",
                [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
                  width: "100%",
                },
              }}
              color={"secondary"}
              label={t`Download S5 (V1) Installer for MAC`}
              onClick={() => handleDownload(installerVM.macosLink)}
            />
          ) : (
            <></>
          )}
          {installerVM.androidLink ? (
            <StyledButton
              sx={{
                width: "370px",
                height: "62px",
                fontSize: "18px",
                [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
                  width: "100%",
                },
              }}
              color={"secondary"}
              label={t`Download S5 (V2) Installer for Android`}
              onClick={() => handleDownload(installerVM.androidLink)}
            />
          ) : (
            <></>
          )}
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            margin: "40px 0px 20px 0px",
            fontSize: "24px",
            fontWeight: 700,
            padding: "3px 20px",
            lineHeight: "28.8px",
            borderRadius: "50px",
            background:
              "linear-gradient(90deg, #D52037 0%, rgba(213, 32, 55, 0) 59.81%)",
            [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
              fontSize: "18px",
            },
          }}
        >
          {t`Installation on MAC`}
        </Box>
        <Box
          sx={{
            backgroundColor: "#202124",
            width: "100%",
            padding: "20px",
            borderRadius: "24px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Box sx={{ width: "100%", height: "fit-content" }}>
            <Box
              sx={{
                width: "130px",
                height: "30px",
                backgroundColor: baseTheme.palette.secondary.main,
                borderRadius: "50px",
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "21.6px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {t`Step ${activeStep + 1}`}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
              height: "fit-content",
              [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
                flexDirection: "column",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "fit-content",
                height: "fit-content",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "14px",
                  gap: "5px",
                  fontWeight: 400,
                  lineHeight: "16.8px",
                  marginBottom: "20px",
                }}
              >
                <InfoIcon sx={{ marginRight: "10px" }} />
                <Box sx={{ display: "inline" }}>
                  <b>{steps[activeStep][0].title}</b>{" "}
                  {steps[activeStep][0].description}{" "}
                  <b>{steps[activeStep][0].action}</b>
                </Box>
              </Box>
              <Box>
                <img
                  src={steps[activeStep][0].imageUrl}
                  className={classes.screen}
                  alt={""}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "fit-content",
                height: "fit-content",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "14px",
                  gap: "5px",
                  fontWeight: 400,
                  lineHeight: "16.8px",
                  marginBottom: "20px",
                  [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
                    margin: "20px 0px",
                  },
                }}
              >
                <InfoIcon sx={{ marginRight: "10px" }} />
                <Box sx={{ display: "inline" }}>
                  <b>{steps[activeStep][1].title}</b>{" "}
                  {steps[activeStep][1].description}{" "}
                  <b>{steps[activeStep][1].action}</b>
                </Box>
              </Box>
              <Box>
                <img
                  src={steps[activeStep][1].imageUrl}
                  className={classes.screen}
                  alt={""}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "32px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              onClick={handlePrevious}
              sx={{
                backgroundColor: "#313641",
                borderRadius: "20px",
                width: "auto",
                height: "100%",
                padding: "10px",
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "21.78px",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                cursor: "pointer",
                gap: "10px",
                userSelect: "none",
                "-webkit-user-select": "none",
                "-moz-user-select": "none",
                "-ms-user-select": "none",
              }}
            >
              <ArrowIcon sx={{ transform: "rotate(90deg)" }} />
              {t`Previous`}
            </Box>
            <Box
              onClick={handleNext}
              sx={{
                backgroundColor: "#313641",
                borderRadius: "20px",
                width: "auto",
                height: "100%",
                padding: "10px",
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "21.78px",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                cursor: "pointer",
                gap: "10px",
                userSelect: "none",
                "-webkit-user-select": "none",
                "-moz-user-select": "none",
                "-ms-user-select": "none",
              }}
            >
              {t`Next`}
              <ArrowIcon sx={{ transform: "rotate(270deg)" }} />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "#202124",
            width: "100%",
            padding: "10px",
            marginTop: "20px",
            borderRadius: "14px",
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          {steps.map((step, index) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <Box
                onClick={() => setActiveStep(index)}
                sx={{
                  background:
                    index === activeStep
                      ? baseTheme.palette.secondary.main
                      : "#313641",
                  borderRadius: "14px",
                  padding: "10px",
                  fontSize: "16px",
                  fontWeight: 700,
                  lineHeight: "19.2px",
                  cursor: "pointer",
                  userSelect: "none",
                  "-webkit-user-select": "none",
                  "-moz-user-select": "none",
                  "-ms-user-select": "none",
                }}
              >{t`Step ${index + 1}`}</Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default appWithStyles(styles)(InstallerPage);
