import React, { FC, useMemo } from "react";
import {
  appWithStyles,
  AppWithStyles,
  Box,
  List,
  ListItemText,
} from "@shared/material";
import { styles } from "./connection-diagram.styles";
import { CarInstallationModel } from "@shared/models/cars/car-installation.dto";
import { appInject } from "@core/di/utils";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { Locale } from "@shared/enum/locale.enum";

export interface ConnectionDiagramProps extends AppWithStyles<typeof styles> {
  data: CarInstallationModel;
}

const ConnectionDiagram: FC<ConnectionDiagramProps> = ({
  data,
}: ConnectionDiagramProps) => {
  const localesViewModel = appInject<ILocalesViewModel>(
    DI_TOKENS.localesViewModel,
  );

  const descriptions = useMemo(
    () => ({
      [Locale.English]: data.asJson.connectionDescription[Locale.English]
        .split("\n")
        .filter((text) => !!text),
      [Locale.Russian]: data.asJson.connectionDescription[Locale.Russian]
        .split("\n")
        .filter((text) => !!text),
      [Locale.Ukrainian]: data.asJson.connectionDescription[Locale.Ukrainian]
        .split("\n")
        .filter((text) => !!text),
    }),
    [data],
  );

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRadius: "10px",
        width: "100%",
        padding: "20px",
      }}
    >
      <List>
        {descriptions[localesViewModel.locale].map((text, index) => {
          return (
            <ListItemText
              key={index}
              sx={{
                color: "#000",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "19.2px",
              }}
              primary={text}
            />
          );
        })}
      </List>
    </Box>
  );
};

export default appWithStyles(styles)(ConnectionDiagram);
