import { TranslationDto } from "@shared/models/translation.dto";

import { BaseModel } from "@core/models/base-model";

export interface CanFunctionDto {
  id: string;
  functionName: TranslationDto;
  notes: TranslationDto;
  readableParams: string;
  executionCommand: string;
}

export class CanFunctionModel extends BaseModel<CanFunctionDto> {
  private id?: string;
  private functionName?: TranslationDto;
  private notes?: TranslationDto;
  private readableParams?: string;
  private executionCommand?: string;

  constructor(data: CanFunctionDto) {
    super();

    this.update(data);
  }

  get asJson(): CanFunctionDto {
    return {
      id: this.id || "",
      readableParams: this.readableParams || "",
      executionCommand: this.executionCommand || "",
      functionName: this.functionName || {
        uk: "",
        en: "",
        ru: "",
      },
      notes: this.notes || {
        uk: "",
        en: "",
        ru: "",
      },
    };
  }
}
