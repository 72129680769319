export const readableFunctionsList = [
  "Driver's door switch",
  "Passenger door switches",
  "Hood switch",
  "Trunk switch",
  "ACC",
  "IGN",
  "The engine is running",
  "Speed of the vehicle",
  "Pressing the brake pedal",
  "State of the parking brake",
  "MT  neutral",
  "MT  reverse",
  "AT  P",
  "AT  R",
  "AT  N",
  "AT  D",
  "SLAVE mode",
  "SLAVE mode for trunk opening",
  "Additional function of the factory key",
  "The status of the factory alarm system in alert mode",
  "State of the central lock",
  "State of the hazard lights",
  "State of the autonomous heater",
  "Fuel level in the tank",
  "Engine temperature reading",
  "Outside temperature reading",
  "Vehicle mileage",
  "Position lights",
  "Low beam headlights",
  "High beam headlights",
  "Seat belts state",
  "Factory remote start",
  "Exterior car button",
  "VIN number",
  "Original buttons",
];
