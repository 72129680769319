import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { AppWithStyles, appWithStyles } from "@shared/material";
import { styles } from "./models.styles";
import { Box, Typography } from "@mui/material";
import { t } from "@lingui/macro";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SectionHeader } from "@shared/components/section-header";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";
import { StyledBreadcrumb } from "@shared/components/styled-breadcrumb";
import { NavItem } from "@shared/types/nav-item";
import { ROUTES } from "../../../routing/routes";
import { appInject } from "@core/di/utils";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { appObserver, appReaction } from "@core/state-management/utils";
import { useQuery } from "react-query";
import { Loader } from "@shared/components/loader";
import { CarModelListModel } from "@shared/models/cars/car-model-list.model";
import { StyledSearchField } from "@shared/components/styled-search-field";
import { ModelsViewModel } from "@ui/private/models/models.vm";
import { ScrollComponent } from "@shared/components/scroll-component";
import { IScrollService } from "@shared/interfaces/scroll-service.interface";

export type ModelsProps = AppWithStyles<typeof styles>;

const Models: FC<ModelsProps> = appObserver(({ classes }: ModelsProps) => {
  const { version, brand } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const scrollService = useMemo(
    () => appInject<IScrollService>(DI_TOKENS.scrollService),
    [],
  );

  useEffect(() => {
    scrollService.setScrollPosition(0);
  }, []);

  const carsModelsQuery = useQuery(
    ["download-models-list"],
    () => $vm.getModelsList(),
    {
      refetchOnMount: true,
      onSuccess: (data: Array<CarModelListModel>) => {
        const map = new Map<string, Array<CarModelListModel>>();
        data.forEach((model) => {
          const modelItem = map.get(model.asJson.model) || [];
          modelItem.push(model);
          map.set(model.asJson.model, modelItem);
        });
        setModelsMap(map);
        setTimeout(() => {
          const positionData = scrollService.getStoredPosition(
            location.pathname,
          );
          if (positionData.position) {
            scrollService.setScrollPosition(positionData.position);
          }
          scrollService.deleteStoredPosition(location.pathname);
        }, 100);
      },
    },
  );

  const $vm: ModelsViewModel = useMemo(
    () =>
      new ModelsViewModel({
        version: version || "",
        brand: brand || "",
        pathname: location.pathname,
      }).setQuery(carsModelsQuery),
    [version, brand],
  );

  const navigateTo = useCallback((path: string) => {
    scrollService.storePosition(
      location.pathname,
      scrollService.currentScrollPosition,
      $vm.searchString,
    );
    return navigate(path);
  }, []);

  const [_, render] = useState(0);

  const localesViewModel = appInject<ILocalesViewModel>(
    DI_TOKENS.localesViewModel,
  );

  appReaction(
    () => localesViewModel.locale,
    () => render(_ + 1),
  );

  const [modelsMap, setModelsMap] = useState<
    Map<string, Array<CarModelListModel>>
  >(new Map<string, Array<CarModelListModel>>());

  let component = <></>;

  if (carsModelsQuery.isFetching) {
    component = (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 367px)",
        }}
      >
        <Loader />
      </Box>
    );
  } else {
    component = (
      <Box
        sx={{
          paddingLeft: "135px",
          paddingRight: "135px",
          [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
            paddingLeft: "24px",
            paddingRight: "24px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "80px",
            [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
              paddingBottom: "100px",
            },
          }}
        >
          {Array.from(modelsMap.keys()).map((modelName, index) => {
            const models = modelsMap.get(modelName) || [];
            const modelsComponent = (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "20px",
                  [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
                    gap: "10px",
                  },
                }}
              >
                {models.map((model) => (
                  <Box
                    onClick={() =>
                      navigateTo(
                        ROUTES.modifications(
                          version,
                          encodeURIComponent(brand || ""),
                          encodeURIComponent(model.asJson.model || ""),
                          encodeURIComponent(model.asJson.years || ""),
                        ),
                      )
                    }
                    key={index}
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "start",
                      overflow: "hidden",
                      width: "378px",
                      height: "260px",
                      background: "#202124",
                      borderRadius: "10px",
                      [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
                        width: "100%",
                      },
                    }}
                  >
                    {model.asJson.imageUrl ? (
                      <Box
                        sx={{
                          width: "378px",
                          height: "212px",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          overflow: "hidden",
                          textWrap: "nowrap",
                          textOverflow: "ellipsis",
                          backgroundImage: `url(${model.asJson.imageUrl})`,
                          borderRadius: "10px",
                        }}
                      ></Box>
                    ) : (
                      <Box
                        sx={{
                          width: "378px",
                          height: "212px",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {model.asJson.model}
                      </Box>
                    )}
                    <Typography
                      component={"div"}
                      sx={{
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "19.2px",
                        paddingTop: "17px",
                        paddingLeft: "17px",
                      }}
                    >
                      {model.asJson.years}
                    </Typography>
                  </Box>
                ))}
              </Box>
            );

            return (
              <>
                <Box
                  sx={{
                    margin: "20px 0px",
                    marginTop: "40px",
                    fontSize: "24px",
                    fontWeight: 700,
                    padding: "3px 20px",
                    lineHeight: "28.8px",
                    borderRadius: "50px",
                    background:
                      "linear-gradient(90deg, #D52037 0%, rgba(213, 32, 55, 0) 59.81%)",
                    [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
                      fontSize: "18px",
                    },
                  }}
                >
                  {modelName}
                </Box>
                {modelsComponent}
              </>
            );
          })}
        </Box>
      </Box>
    );
  }

  const breadcrumbs: NavItem[] = [
    { label: t`Product`, path: ROUTES.catalog },
    {
      label: `S5 (${version?.toUpperCase() || ""})`,
      path: ROUTES.brands(version),
    },
    { label: brand || "", path: "" },
  ];

  return (
    <Box>
      <ScrollComponent />
      <Box
        sx={{
          paddingLeft: "135px",
          paddingRight: "135px",
          [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
            paddingLeft: "24px",
            paddingRight: "24px",
          },
        }}
      >
        <StyledBreadcrumb paths={breadcrumbs} />
      </Box>
      <SectionHeader
        title={brand || ""}
        searchField={
          <Box
            sx={{
              width: "30vw",
              [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
                width: "100%",
                position: "absolute",
                bottom: "-80px",
                left: "0px",
              },
            }}
          >
            <StyledSearchField
              placeholder={t`Search`}
              onSearch={(e) => $vm.setSearchString(e)}
              value={$vm.searchString}
            />
          </Box>
        }
      />
      {component}
    </Box>
  );
});

export default appWithStyles(styles)(Models);
