import { SvgIcon, SvgIconProps } from "@shared/material";
import React from "react";

function WelcomeScreenLogoIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="316"
      height="112"
      viewBox="0 0 316 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.0088 31.6847H49.4437V34.6463C49.4437 38.5851 47.8608 40.1904 43.6804 40.1904H19.2967C15.4656 40.1904 13.4441 38.2953 13.4441 34.6463V19.3331C13.4441 14.6659 15.4656 12.5516 20.4114 12.5516H60.4094V0.809326H18.8099C6.96731 0.809326 0 7.22668 0 18.3112V34.3565C0 45.7308 6.61801 51.8583 18.8099 51.8583H44.0743C55.5675 51.8583 62.3268 45.6602 62.3268 34.8655V20.7786H23.0088V31.6847Z"
        fill="white"
      />
      <path
        d="M109.296 0.809326H71.3864V12.5478H108.783C112.614 12.5478 114.19 14.443 114.19 18.0177V20.0614H85.4473C73.954 20.0614 67.1948 25.8954 67.1948 35.958C67.1948 46.0207 73.9503 51.8583 85.4473 51.8583H108.408C120.25 51.8583 127.218 45.441 127.218 34.3565V18.3112C127.218 6.93313 121.488 0.809326 109.3 0.809326H109.296ZM114.186 33.4758C114.186 38.2173 112.165 40.1867 107.222 40.1867H86.0047C82.4374 40.1867 80.4309 38.8007 80.4309 35.5195C80.4309 32.2384 82.4374 30.8524 86.0047 30.8524H114.19V33.4758H114.186Z"
        fill="white"
      />
      <path
        d="M149.234 38.4365L180.909 20.7897C185.297 18.3818 187.319 15.0895 187.319 10.5821C187.319 4.6032 182.878 0.812988 177.564 0.812988H132.661V12.5515H168.594C169.657 12.5515 169.824 13.7666 168.943 14.2311L137.265 31.8779C132.948 34.2858 130.855 37.5223 130.855 42.0892C130.855 46.656 134.348 51.862 140.822 51.862H185.591V40.1941H149.584C148.491 40.1941 148.324 38.9158 149.234 38.4439V38.4365Z"
        fill="white"
      />
      <path
        d="M233.518 32.6025C245.011 32.6025 251.771 26.7686 251.771 16.7059C251.771 6.64329 245.015 0.809326 233.518 0.809326H210.022C198.18 0.809326 191.216 7.22668 191.216 18.3112V34.3565C191.216 45.7308 197.834 51.8583 210.022 51.8583H248.136V40.1904H210.513C206.403 40.1904 204.244 38.1504 204.244 34.2821V32.6063H233.522L233.518 32.6025ZM204.241 19.0804C204.241 14.4132 206.262 12.4735 211.208 12.4735H232.961C237 12.4735 238.531 13.8373 238.531 17.1407C238.531 20.4441 237 21.8079 232.961 21.8079H204.241V19.0804Z"
        fill="white"
      />
      <path
        d="M308.405 29.5406C307.888 29.3251 307.873 28.686 308.371 28.463C313.324 26.2186 316 22.1014 316 16.2675C316 6.34973 309.449 0.809326 297.751 0.809326H274.62C262.539 0.809326 257.147 7.50537 257.147 18.5899V51.8546H270.383V34.8247H295.548C300.215 34.8247 301.653 36.3556 301.653 40.1495V51.8546H315.026V40.4803C315.026 34.9361 313.001 31.4543 308.412 29.5406H308.405ZM296.083 23.7476H270.376V19.0804C270.376 14.4132 271.952 12.4735 276.897 12.4735H296.083C300.542 12.4735 302.548 14.521 302.548 18.2703C302.548 21.6629 300.542 23.7476 296.083 23.7476Z"
        fill="white"
      />
      <rect y="69.925" width="316" height="41.3709" fill="#D52037" />
      <path
        d="M43.7275 100.754V81.4661H47.6847L53.3574 90.7518L59.0025 81.4661H62.8212V100.754H59.2516V87.2249L53.6895 96.3728H52.8592L47.2695 87.1698V100.754H43.7275Z"
        fill="white"
      />
      <path
        d="M79.8673 81.4665V84.828H70.625V89.3466H78.9544V92.6534H70.625V97.3925H80.0057V100.754H67.083V81.4661H79.8673V81.4665Z"
        fill="white"
      />
      <path
        d="M96.4432 84.7725H90.6597V100.754H87.0624V84.7725H81.3066V81.4661H96.4432V84.7725Z"
        fill="white"
      />
      <path
        d="M106.821 81.4661L114.375 100.754H110.501L108.952 96.6484H100.512L98.9623 100.754H95.2266L102.809 81.4661H106.821H106.821ZM107.762 93.4798L104.718 85.4339L101.702 93.4798H107.762Z"
        fill="white"
      />
      <path
        d="M122.677 97.8057C124.669 97.8057 126.219 97.1718 126.219 95.5186C126.219 94.0584 125.444 93.4245 122.096 92.6256C117.336 91.4957 115.703 89.7874 115.703 86.7014C115.703 83.6155 118.692 81.1631 122.373 81.1631C126.053 81.1631 128.488 82.8163 129.456 85.5168L126.274 86.8117C125.721 85.2963 124.254 84.3868 122.455 84.3868C120.49 84.3868 119.3 85.1309 119.3 86.7566C119.3 88.079 120.02 88.6578 123.147 89.3464C127.823 90.4212 129.815 92.102 129.815 95.243C129.815 98.6872 126.799 101.029 122.704 101.029C118.885 101.029 116.035 99.2109 115.066 96.2352L118.138 94.8296C118.747 96.7033 120.241 97.8057 122.676 97.8057H122.677Z"
        fill="white"
      />
      <path
        d="M133.164 100.754V81.4661H137.121L142.794 90.7518L148.439 81.4661H152.258V100.754H148.688V87.2249L143.126 96.3728H142.296L136.706 87.1698V100.754H133.164H133.164Z"
        fill="white"
      />
      <path
        d="M166.288 81.4661L173.842 100.754H169.968L168.419 96.6484H159.979L158.43 100.754H154.694L162.276 81.4661H166.288H166.288ZM167.229 93.4798L164.185 85.4339L161.169 93.4798H167.229Z"
        fill="white"
      />
      <path
        d="M176.278 81.4661H184.054C188.564 81.4661 191.165 83.698 191.165 87.1973C191.165 89.0159 190.169 90.4762 188.426 91.3581C190.114 91.9644 191.138 93.3971 191.138 95.2708V100.754H187.54V95.4913C187.54 94.1413 186.738 93.3696 185.382 93.3696H179.82V100.754H176.278V81.4661ZM183.749 90.173C186.405 90.173 187.485 89.3738 187.485 87.4454C187.485 85.517 186.405 84.7725 183.749 84.7725H179.82V90.173H183.749Z"
        fill="white"
      />
      <path
        d="M207.603 84.7725H201.819V100.754H198.222V84.7725H192.466V81.4661H207.603V84.7725Z"
        fill="white"
      />
      <path
        d="M226.835 81.1353C230.515 81.1353 233.531 82.8436 235.192 85.5992L231.982 87.528C230.958 85.6543 229.077 84.5247 226.697 84.5247C222.961 84.5247 220.415 87.2252 220.415 91.11C220.415 94.9947 222.989 97.6956 226.725 97.6956C229.215 97.6956 231.18 96.5657 232.231 94.6644L235.358 96.4554C233.643 99.2937 230.516 101.085 226.863 101.085C220.941 101.085 216.68 96.8964 216.68 91.11C216.68 85.3236 221.08 81.1353 226.835 81.1353H226.835Z"
        fill="white"
      />
      <path
        d="M247.395 81.4661L254.95 100.754H251.076L249.526 96.6484H241.087L239.537 100.754H235.801L243.383 81.4661H247.396H247.395ZM248.336 93.4798L245.293 85.4339L242.276 93.4798H248.336Z"
        fill="white"
      />
      <path
        d="M257.385 81.4661H265.161C269.672 81.4661 272.273 83.698 272.273 87.1973C272.273 89.0159 271.277 90.4762 269.533 91.3581C271.221 91.9644 272.245 93.3971 272.245 95.2708V100.754H268.648V95.4913C268.648 94.1413 267.845 93.3696 266.489 93.3696H260.927V100.754H257.385V81.4661ZM264.857 90.173C267.513 90.173 268.592 89.3738 268.592 87.4454C268.592 85.517 267.513 84.7725 264.857 84.7725H260.927V90.173H264.857Z"
        fill="white"
      />
      <path
        d="M279.951 77.7725H280.927L283.223 83.3558H283.302L285.597 77.7725H286.574V84.4988H285.809V79.3884H285.743L283.632 84.4988H282.893L280.782 79.3884H280.716V84.4988H279.951V77.7725Z"
        fill="white"
      />
      <path
        d="M273.622 78.495V77.7725H278.688V78.495H276.564V84.4988H275.746V78.495H273.622Z"
        fill="white"
      />
    </SvgIcon>
  );
}

export default WelcomeScreenLogoIcon;
