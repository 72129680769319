import { appCreateStyles } from "@shared/material";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";

export function styles() {
  return appCreateStyles({
    logo: {
      position: "absolute",
      top: "50%",
      transform: "translate(0, -100%)",
    },
    locales: {
      position: "absolute",
      top: "10px",
      right: "-10px",
      [baseTheme.breakpoints.up(BreakpointLayout.LAPTOP)]: {
        transform: "translate(-50%, 0)",
        bottom: "10vh",
        left: "50%",
        top: "unset",
        right: "unset",
      },
    },
  });
}
